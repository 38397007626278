import React, { useState } from "react";
import "./Disclaimer.css";
import logo from "./SOEL-LOGO2.png";

const Disclaimer = ({ onAccept }) => {
  const [accepted, setAccepted] = useState(false);

  const handleAccept = () => {
    setAccepted(true);
    onAccept();
  };

  if (!accepted) {
    return (
      <div className="disclaimer-popup">
        <div className="disclaimer-content">
          <h3>Disclaimer</h3>
          <p>
            This YC Safe Calculator and Cap Table Calculator is provided for
            informational purposes only and is not intended as legal or
            financial advice. By using this calculator, you acknowledge that no
            attorney-client relationship is formed and that you should consult
            with a qualified professional for advice specific to your situation.
            Your use of this calculator is at your own risk, and the creators of
            this calculator make no warranties or guarantees regarding its
            accuracy or applicability to your particular circumstances. By
            clicking "I Accept," you agree to these terms.
          </p>
          <p>
            This calculator and its content, including any intellectual property
            rights therein, are the property of So-El, Inc. and are protected by
            applicable intellectual property laws. Any unauthorized copying,
            distribution, or use of this calculator is strictly prohibited and
            may result in legal action.
          </p>
          <button onClick={handleAccept}>I Accept</button>
        </div>
        <img src={logo} alt="So-El Logo" className="footer-logo" />
      </div>
    );
  }

  return null;
};

export default Disclaimer;
