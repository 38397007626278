import React, { useState, useEffect } from "react";
import YCsafe from "./YCsafe";
import Disclaimer from "./Disclaimer";

const App = () => {
  const [showDisclaimer, setShowDisclaimer] = useState(true);

  useEffect(() => {
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.async = true;
    script.innerHTML = `
      window.smartlook||(function(d) {
        var o=smartlook=function(){ o.api.push(arguments)},h=d.getElementsByTagName('head')[0];
        var c=d.createElement('script');o.api=new Array();c.async=true;c.type='text/javascript';
        c.charset='utf-8';c.src='https://web-sdk.smartlook.com/recorder.js';h.appendChild(c);
      })(document);
      smartlook('init', 'cb046f344c4f8f417313b6100c35dfab0b996f73', { region: 'eu' });
    `;
    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, []);

  const handleDisclaimerAccept = () => {
    setShowDisclaimer(false);
  };

  return (
    <div className="app">
      {showDisclaimer && <Disclaimer onAccept={handleDisclaimerAccept} />}
      {!showDisclaimer && <YCsafe />}
    </div>
  );
};

export default App;
