import React, { useEffect, useState } from "react";
import Plot from "react-plotly.js";
import gsap from "gsap";

import "./YCsafe.css";
import ValuationCalculator from "./ValuationCalculator";

const YCsafe = () => {
  const [valuation, setValuation] = useState(0);
  const [capTable, setCapTable] = useState([
    { name: "", ownership: 0, shares: 0 },
  ]);
  const [numShares, setNumShares] = useState(100000);
  const [formattedShares, setFormattedShares] = useState("100000");
  const [discountPercentage, setDiscountPercentage] = useState(0);
  const [safeInvestmentAmount, setSafeInvestmentAmount] = useState(0);
  const [valuationCap, setValuationCap] = useState(0);
  const [seriesAInvestment, setSeriesAInvestment] = useState(0);
  const [isPreMoney, setIsPreMoney] = useState(true);
  const [employeePoolPercentage, setEmployeePoolPercentage] = useState(0);
  const [newCapTable, setNewCapTable] = useState([]);
  const [ownershipErrors, setOwnershipErrors] = useState([]);
  const [investmentAmountError, setInvestmentAmountError] = useState(false);
  const [valuationCapError, setValuationCapError] = useState(false);
  const [seriesAInvestmentError, setSeriesAInvestmentError] = useState(false);
  const [preMoneyValuationError, setPreMoneyValuationError] = useState(false);
  const [employeePoolPercentageError, setEmployeePoolPercentageError] =
    useState(false);

  // Update formattedShares whenever numShares changes
  useEffect(() => {
    setFormattedShares(numShares.toLocaleString());
  }, [numShares]);

  // Update both numShares and formattedShares when the input changes
  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    // Remove all non-numeric characters except for digits
    const cleanedInput = inputValue.replace(/[^0-9]/g, "");
    // Parse the cleaned input as an integer
    const parsedInput = parseInt(cleanedInput, 10) || 0;
    setNumShares(parsedInput);
  };
  const [showChart, setShowChart] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [showAdvanced, setShowAdvanced] = useState(false);
  const [preMoneyValuationSeriesA, setPreMoneyValuationSeriesA] =
    useState(null);

  const [hoveredRow, setHoveredRow] = useState(null);

  const deleteRow = (index) => {
    setCapTable(capTable.filter((_, idx) => idx !== index));
  };

  const handleOwnershipChange = (e, index, field) => {
    const updatedCapTable = [...capTable];
    const value = e.target.value;
    const errors = [...ownershipErrors];

    if (field === "ownership") {
      updatedCapTable[index][field] = parseFloat(value) || 0;
      updatedCapTable[index]["shares"] = (parseFloat(value) / 100) * numShares;

      if (value < 0 || value > 100 || isNaN(value)) {
        errors[index] = true;
      } else {
        errors[index] = false;
      }
    } else {
      updatedCapTable[index][field] = value;
    }

    setCapTable(updatedCapTable);
    setOwnershipErrors(errors);
  };

  const formatCurrency = (value) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(value);
  };

  const handlePreMoneyValuationChange = (value) => {
    setValuation(value);
    setPreMoneyValuationSeriesA(value);
  };

  const calculateNewCapTable = () => {
    let safeShares, seriesAShares;
    let adjustedValuation;

    // Validate the cap and discount
    if (valuationCap > 0 && discountPercentage > 0) {
      adjustedValuation = Math.min(
        valuation * (1 - discountPercentage / 100),
        valuationCap,
      );
    } else if (valuationCap > 0) {
      adjustedValuation = valuationCap;
    } else {
      adjustedValuation = valuation * (1 - discountPercentage / 100);
    }

    // Calculate SAFE shares
    if (isPreMoney) {
      safeShares = (safeInvestmentAmount * numShares) / adjustedValuation;
    } else {
      safeShares =
        (safeInvestmentAmount * numShares) /
        (adjustedValuation + safeInvestmentAmount);
    }

    // Calculate Series A shares
    const preMoneyValuationSeriesA = isPreMoney
      ? valuation
      : valuation + seriesAInvestment;
    seriesAShares =
      (seriesAInvestment * (numShares + safeShares)) / preMoneyValuationSeriesA;

    const totalSharesAfterSAFEAndSeriesA =
      numShares + safeShares + seriesAShares;

    // The fully diluted shares, taking the already defined employee pool into account
    const fullyDilutedShares =
      totalSharesAfterSAFEAndSeriesA / (1 - employeePoolPercentage / 100);

    // Update cap table
    const newCapTable = capTable.map((shareholder) => {
      return {
        ...shareholder,
        ownership: (shareholder.shares / fullyDilutedShares) * 100,
      };
    });

    newCapTable.push({
      name: "SAFE Investors",
      ownership: (safeShares / fullyDilutedShares) * 100,
      shares: safeShares,
    });

    newCapTable.push({
      name: "Series A Investors",
      ownership: (seriesAShares / fullyDilutedShares) * 100,
      shares: seriesAShares,
    });

    newCapTable.push({
      name: "Employee Pool",
      ownership: employeePoolPercentage,
      shares: (employeePoolPercentage / 100) * fullyDilutedShares,
    });

    // Validate that ownership percentages sum to 100%
    const totalOwnership = newCapTable.reduce(
      (acc, shareholder) => acc + shareholder.ownership,
      0,
    );
    if (Math.abs(totalOwnership - 100) > 0.01) {
      // Throw an error or set an error state
      console.error("Ownership does not sum to 100%");
    } else {
      setNewCapTable(newCapTable);
      setShowChart(true);
    }
  };

  const pieChartData = newCapTable.map((shareholder) => ({
    label: shareholder.name,
    percentage: shareholder.ownership.toFixed(2) + "%",
    value: shareholder.ownership,
  }));

  const [hoveredSegment, setHoveredSegment] = useState(null);

  const handleMouseOver = (index) => {
    setHoveredRow(index);
  };

  const handleMouseOut = () => {
    setHoveredRow(null);
  };

  const handleSubmit = () => {
    let hasEmptyFields = false;

    capTable.forEach((shareholder) => {
      if (!shareholder.name || shareholder.name.trim() === "") {
        hasEmptyFields = true;
      }

      if (!shareholder.ownership || isNaN(shareholder.ownership)) {
        hasEmptyFields = true;
      }
    });

    calculateNewCapTable();
  };

  return (
    <div className="container">
      <div className="ycsafe">
        <div className="ycsafe">
          <h2>So-El's YC Safe Calculator</h2>
          <p>
            Welcome to the YC Safe Calculator! This tool helps you calculate the
            new cap table for your startup after raising funds through a Y
            Combinator (YC) Safe financing round. Enter the necessary details
            below and click "Calculate" to see the updated cap table.
          </p>
          <div className="input-group">
            <div className="centered-container">
              <div className="input-section safe-round">
                <div className="input-section">
                  <h3>Existing Cap Table</h3>
                  <div className="input-row">
                    <div className="input-row">
                      <label className="input-label">
                        Total Number of Shares:
                      </label>
                      <div className="input-column">
                        <input
                          type="text"
                          value={formattedShares}
                          onChange={handleInputChange}
                          className="input-field safe"
                        />
                        <div className="info-tooltip">
                          Total number of shares currently in existence.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="input-row">
                    <table className="input-table">
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Ownership (%)</th>
                          <th>Shares</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {capTable.map((shareholder, index) => (
                          <tr key={index}>
                            <td>
                              <input
                                type="text"
                                value={shareholder.name}
                                onChange={(e) =>
                                  handleOwnershipChange(e, index, "name")
                                }
                                className={
                                  !shareholder.name ? "error-field" : ""
                                }
                              />
                            </td>
                            <td>
                              <input
                                type="number"
                                value={shareholder.ownership}
                                onChange={(e) =>
                                  handleOwnershipChange(e, index, "ownership")
                                }
                                className={
                                  !shareholder.ownership ? "error-field" : ""
                                }
                              />
                              {ownershipErrors[index] && (
                                <span className="error">Invalid ownership</span>
                              )}
                            </td>
                            <td>{shareholder.shares}</td>
                            <td>
                              <button
                                onClick={() => deleteRow(index)}
                                className="input-button"
                              >
                                Delete
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <button
                    className="input-button-calculate"
                    onClick={() =>
                      setCapTable([
                        ...capTable,
                        { name: "", ownership: 0, shares: 0 },
                      ])
                    }
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    Add Row
                  </button>
                </div>
              </div>
            </div>

            <div className="centered-container">
              <div className="input-section safe-round">
                <h3>SAFE Round</h3>

                <div className="centered-container">
                  <div className="input-section1 safe-round">
                    <div className="input-row">
                      <label className="input-label-safe">
                        Select type of SAFE:
                      </label>
                      <div className="card-container">
                        <div
                          className={`card ${isPreMoney ? "selected" : ""}`}
                          onClick={() => setIsPreMoney(true)}
                        >
                          <h4>Pre-Money</h4>
                          <div className="hover-info">
                            In this type, the valuation cap and discount are
                            applied before the investment, which means the
                            investor's ownership is calculated based on the
                            valuation before the investment.
                          </div>
                        </div>
                        <div
                          className={`card ${isPreMoney ? "" : "selected"}`}
                          onClick={() => setIsPreMoney(false)}
                        >
                          <h4>Post-Money</h4>
                          <div className="hover-info">
                            In this type, the valuation cap and discount are
                            applied after the investment, which means the
                            investor's ownership is calculated based on the
                            valuation after the investment.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="input-row">
                  <div className="input-column">
                    <label className="input-label">Investment Amount ($)</label>
                    <input
                      type="number"
                      value={safeInvestmentAmount}
                      onChange={(e) =>
                        setSafeInvestmentAmount(parseFloat(e.target.value))
                      }
                      className={`input-field safe${
                        !safeInvestmentAmount ? " error-field" : ""
                      }`}
                      placeholder="Enter Investment Amount"
                      min="0"
                    />
                    <div className="info-tooltip">
                      Amount of money invested by SAFE investors.
                    </div>
                  </div>

                  <div className="input-column">
                    <label className="input-label">
                      Discount Percentage (%)
                    </label>
                    <input
                      type="number"
                      value={discountPercentage}
                      onChange={(e) =>
                        setDiscountPercentage(parseFloat(e.target.value))
                      }
                      className={`input-field safe${
                        !discountPercentage ? " error-field" : ""
                      }`}
                      placeholder="Enter Discount Percentage"
                      min="0"
                      max="100" // Set max to 100 to enforce a percentage value
                      step="0.01"
                    />
                    <div className="info-tooltip">
                      The discount rate SAFE investors get on their shares.
                    </div>
                  </div>

                  <div className="input-column">
                    <label className="input-label">Valuation Cap ($)</label>
                    <input
                      type="number"
                      value={valuationCap}
                      onChange={(e) =>
                        setValuationCap(parseFloat(e.target.value))
                      }
                      className={`input-field safe${
                        !valuationCap ? " error-field" : ""
                      }`}
                      placeholder="Enter Valuation Cap"
                      min="0"
                    />
                    <div className="info-tooltip">
                      The maximum company valuation for calculating SAFE
                      investors' ownership.
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="centered-container">
              <div className="input-section safe-round">
                <h3>Priced Round</h3>
                <div className="input-row">
                  <div className="input-column">
                    <label className="input-label">
                      Series A Investment Amount ($)
                    </label>
                    <input
                      type="number"
                      value={seriesAInvestment}
                      onChange={(e) =>
                        setSeriesAInvestment(parseFloat(e.target.value))
                      }
                      className={`input-field safe${
                        !seriesAInvestment ? " error-field" : ""
                      }`}
                      placeholder="Enter Series A Investment Amount"
                      min="0"
                    />
                    <div className="info-tooltip">
                      Amount of money invested by Series A investors.
                    </div>
                  </div>

                  <div className="input-column">
                    <label className="input-label">
                      Pre-Money Valuation ($)
                    </label>
                    <input
                      type="number"
                      value={valuation !== null ? valuation : 0}
                      onChange={(e) => setValuation(parseFloat(e.target.value))}
                      className={`input-field safe${
                        !valuation ? " error-field" : ""
                      }`}
                      placeholder="Enter Pre-Money Valuation"
                      min="0"
                    />
                    <div className="info-tooltip">
                      The valuation of the company before the investment round.
                    </div>
                  </div>

                  <div className="input-column">
                    <label className="input-label">
                      Employee Pool Percentage (%)
                    </label>
                    <input
                      type="number"
                      value={employeePoolPercentage}
                      onChange={(e) =>
                        setEmployeePoolPercentage(parseFloat(e.target.value))
                      }
                      className={`input-field safe${
                        !employeePoolPercentage ? " error-field" : ""
                      }`}
                      placeholder="Enter Employee Pool Percentage"
                      min="0"
                      max="100" // Set max to 100 to enforce a percentage value
                      step="0.01"
                    />
                    <div className="info-tooltip">
                      The percentage of the company's shares reserved for the
                      employee pool in the Series A round.
                    </div>
                  </div>
                </div>
                <div className="input-row-advanced">
                  <div className="advanced-button-container">
                    <button onClick={() => setShowAdvanced(!showAdvanced)}>
                      {showAdvanced ? "Hide Advanced" : "Show Advanced"}
                    </button>
                  </div>
                  {showAdvanced && (
                    <ValuationCalculator onValuationChange={setValuation} />
                  )}
                </div>
              </div>
            </div>
          </div>

          <button onClick={handleSubmit} className="input-button-calculate">
            Calculate
          </button>

          {showPopup && (
            <div className="popup">
              <span>
                Please fill in all the necessary data before calculating.
              </span>
            </div>
          )}
          {showChart && (
            <div className="chart-details">
              <div className="new-cap-table-container">
                <h3>New Cap Table</h3>
                <table className="input-table">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Ownership (%)</th>
                      <th>Shares</th>
                    </tr>
                  </thead>
                  <tbody>
                    {newCapTable.map((shareholder, index) => (
                      <tr
                        key={index}
                        className={index === hoveredRow ? "hovered-row" : ""}
                        onMouseOver={() => handleMouseOver(index)}
                        onMouseOut={handleMouseOut}
                      >
                        <td>{shareholder.name}</td>
                        <td>{shareholder.ownership.toFixed(2)}</td>
                        <td>{shareholder.shares}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="pie-chart-container">
                <Plot
                  data={[
                    {
                      labels: pieChartData.map((datum) => datum.label),
                      values: pieChartData.map((datum) => datum.value),
                      type: "pie",
                    },
                  ]}
                  layout={{
                    width: 700,
                    height: 700,
                    scene: {
                      camera: {
                        eye: { x: 2.5, y: 1.5, z: 1.5 }, // Adjust the camera position for a better 3D effect
                      },
                    },
                    paper_bgcolor: "#111", // Set the background color here
                    font: {
                      family: "Arial, sans-serif", // Set the desired font family here
                      size: 14, // Set the desired font size here
                      color: "#fff", // Set the desired font color here
                    },
                  }}
                />
              </div>
            </div>
          )}
          <div className="footer">
            <div className="resources">
              <h3>Resources</h3>
              <ul>
                <li>
                  YC SAFE FINANCING DOCUMENTS:
                  https://www.ycombinator.com/documents
                </li>
                <li>
                  Understanding SAFEs and Priced Equity Rounds:
                  https://www.ycombinator.com/library/6m-understanding-safes-and-priced-equity-rounds
                </li>
              </ul>
            </div>
          </div>

          <footer className="legal-disclaimer">
            <p>
              This calculator is provided for your informational purposes only
              by So-el, Inc. (“So-el”). So-el does not provide legal, tax, or
              financial advice, and the use of this calculator is not a
              substitute for such professional advice or services nor should it
              be used as a basis for any decision or action that may affect your
              business or interests. USE OF THIS CALCULATOR IS ENTIRELY AT YOUR
              OWN RISK, AND SO-EL ASSUMES NO LIABILITY FOR THE USE OF OR
              RELIANCE ON THIS CALCULATOR. So-el provides no assurances of this
              calculator’s applicability or accuracy with respect to your
              particular circumstances. This calculator is provided “as is”
              without warranty of any kind, either express, implied, or
              statutory, including without limitation, warranties of
              merchantability, fitness for a particular purpose, satisfactory
              purpose, title or noninfringement. Some jurisdictions do not allow
              the exclusion of implied warranties, so these exclusions may not
              apply to you.
            </p>
          </footer>
        </div>
      </div>
    </div>
  );
};

export default YCsafe;
