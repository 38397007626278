import React, { useState, useEffect } from "react";
import "./ValuationCalculator.css";
import gsap from "gsap";

const ValuationCalculator = ({ closeComponent, onValuationChange }) => {
  const [startupType, setStartupType] = useState(null);
  const [growthRate, setGrowthRate] = useState(null);
  const [metrics, setMetrics] = useState({ month: "", mrr_gmv: "" });
  const [forecastedGmvMrr, setForecastedGmvMrr] = useState(null);
  const [valuation, setValuation] = useState(null);
  const [valuationHistory, setValuationHistory] = useState([0]); // added this line
  const [showPopup, setShowPopup] = useState(false);
  const [popupContent, setPopupContent] = useState("");

  const multipliers = {
    SaaS: { Low: 5, Moderate: 9, High: 15 },
    Marketplace: { Low: 2.5, Moderate: 4.5, High: 9 },
  };

  const handleStartupType = (type) => {
    setStartupType(type);
    setMetrics({ ...metrics, mrr_gmv: "" });
  };

  const handleGrowthRate = (rate) => {
    setGrowthRate(rate);
  };

  const handleMetricsChange = (e) => {
    setMetrics({ ...metrics, [e.target.name]: e.target.value });
  };

  const calculateValuation = () => {
    setForecastedGmvMrr(null);
    setValuation(null);

    const month = parseFloat(metrics.month);
    const currentGmvMrr = parseFloat(metrics.mrr_gmv);

    let multiplier = multipliers[startupType][growthRate];
    let forecastedGmvMrr =
      currentGmvMrr * Math.pow(1 + multiplier / 100, month);
    let valuation = 12 * forecastedGmvMrr * multiplier;

    setForecastedGmvMrr(forecastedGmvMrr);
    setValuation(valuation);
    setValuationHistory([...valuationHistory, valuation]); // added this line

    gsap.from(".valuation-result", {
      opacity: 0,
      duration: 0.5,
      ease: "power2.out",
    });

    if (onValuationChange) {
      onValuationChange(valuation);
    }
  };

  const formatCurrency = (value) => {
    return value.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    });
  };

  const handlePopup = (content) => {
    setPopupContent(content);
    setShowPopup(true);
  };

  const closePopup = () => {
    setShowPopup(false);
  };

  // Log valuation history whenever it changes
  useEffect(() => {
    console.log("Valuation History:", valuationHistory);
  }, [valuationHistory]);

  return (
    <div className="valuation-calculator">
      <h2 className="calculator-heading">So-El Basic Valuation Calculator</h2>

      <div className="startup-type-buttons">
        <p className="button-label">
          Choose startup type:
          <span className="info-button-container">
            <span
              className="info-button"
              onMouseEnter={() =>
                handlePopup(
                  "The startup type determines the applicable valuation multipliers. SaaS (Software as a Service) and Marketplace have different multipliers based on industry standards.",
                )
              }
              onMouseLeave={closePopup}
            >
              i
            </span>
            <span className="info-button-tooltip">{popupContent}</span>
          </span>
        </p>

        <div className="flex space-x-4">
          <button
            className={`startup-type-button ${
              startupType === "Marketplace" ? "active" : ""
            }`}
            onClick={() => handleStartupType("Marketplace")}
          >
            Marketplace
          </button>
          <button
            className={`startup-type-button ${
              startupType === "SaaS" ? "active" : ""
            }`}
            onClick={() => handleStartupType("SaaS")}
          >
            SaaS
          </button>
        </div>
      </div>
      {startupType && (
        <div className="growth-rate-buttons">
          <p className="button-label">
            Choose growth rate:
            <span className="info-button-container">
              <span
                className="info-button"
                onMouseEnter={() =>
                  handlePopup(
                    "The growth rate represents the expected growth rate of the startup. It affects the valuation multiplier applied to the forecasted GMV/MRR.",
                  )
                }
                onMouseLeave={closePopup}
              >
                i
              </span>
              <span className="info-button-tooltip">{popupContent}</span>
            </span>
          </p>
          <div className="flex space-x-4">
            <button
              className={`growth-rate-button ${
                growthRate === "Low" ? "active" : ""
              }`}
              onClick={() => handleGrowthRate("Low")}
            >
              Low
            </button>
            <button
              className={`growth-rate-button ${
                growthRate === "Moderate" ? "active" : ""
              }`}
              onClick={() => handleGrowthRate("Moderate")}
            >
              Moderate
            </button>
            <button
              className={`growth-rate-button ${
                growthRate === "High" ? "active" : ""
              }`}
              onClick={() => handleGrowthRate("High")}
            >
              High
            </button>
          </div>
        </div>
      )}
      {growthRate && (
        <div className="metric-inputs">
          <div className="metric-input">
            <label htmlFor="month" className="input-label">
              Month till Series A:
              <span className="info-button-container">
                <span
                  className="info-button"
                  onMouseEnter={() =>
                    handlePopup(
                      "The number of months until the Series A funding round. It affects the forecasted GMV/MRR calculation.",
                    )
                  }
                  onMouseLeave={closePopup}
                >
                  i
                </span>
                <span className="info-button-tooltip">{popupContent}</span>
              </span>
              <input
                type="number"
                id="month"
                name="month"
                placeholder="Month till Series A"
                onChange={handleMetricsChange}
                className="input-field safe"
              />
            </label>
          </div>
          <div className="metric-input">
            <label htmlFor="mrr_gmv" className="input-label">
              {startupType === "SaaS" ? "MRR ($/month)" : "GMV ($)"}:
              <span className="info-button-container">
                <span
                  className="info-button"
                  onMouseEnter={() =>
                    handlePopup(
                      `The current ${startupType === "SaaS" ? "MRR" : "GMV"} (${
                        startupType === "SaaS" ? "monthly" : "yearly"
                      }). It affects the forecasted GMV/MRR calculation.`,
                    )
                  }
                  onMouseLeave={closePopup}
                >
                  i
                </span>
                <span className="info-button-tooltip">{popupContent}</span>
              </span>
              <input
                type="number"
                id="mrr_gmv"
                name="mrr_gmv"
                placeholder={`Current ${
                  startupType === "SaaS" ? "MRR" : "GMV"
                } (${startupType === "SaaS" ? "monthly" : "yearly"})`}
                onChange={handleMetricsChange}
                className="input-field safe"
              />
            </label>
          </div>
          <button className="calculate-button" onClick={calculateValuation}>
            Calculate Valuation
          </button>
        </div>
      )}
      {valuation !== null && (
        <div className="valuation-result">
          <p className="result-text">
            The forecasted {startupType === "SaaS" ? "MRR" : "GMV"} at month{" "}
            {metrics.month} is {formatCurrency(forecastedGmvMrr)}
          </p>
          <p className="result-text">
            The estimated valuation of the company is{" "}
            {formatCurrency(valuation)}
          </p>
        </div>
      )}
      {showPopup && (
        <div className="popup">
          <p className="popup-content">{popupContent}</p>
        </div>
      )}
      <div className="disclaimer-text">
        <p>
          *Please note that the valuation calculated here is a simplified
          estimate and should not be considered as financial advice. The actual
          valuation of a startup involves various complex factors and should be
          evaluated by finance professionals.
        </p>
      </div>
    </div>
  );
};

export default ValuationCalculator;
